<template>
	<v-snackbar :value="value" @input="change" color="primary">{{$t("misc.saved")}}</v-snackbar>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// displays a saved message in a snackbar
	// @group Notices
	export default {
		name: "Saved", 
		props: {
			// the triggering value for the snackbar notice
			value: { type: [Boolean, String ], default: false }, 
		},
		methods: {
			change(){
				// emits the input event when the snackbar is hidden
				this.$emit('input')
			}
		}

	}
// </script>"