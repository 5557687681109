<template>
	<v-snackbar :value="value" @input="change" color="red" text>{{message || $t("misc.general_error") }}</v-snackbar>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// displays a snackbar styled as an error with a provided or default error message
	// @group Notices
	export default {
		name: "ErrorMessage", 
		props: {
			// the triggering value for the snackbar notice
			value: { type: [Boolean, String ] }, 
			// the text for the notice
			message: { type: String }
		},
		methods: {
			change(){
				// emits the input event when the snackbar is hidden
				this.$emit('input')
			}
		}

	}
// </script>"