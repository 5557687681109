<template>
	<v-sheet color="transparent" class="mb-12">
		<question
			:disabled="error"
			:errorMessage="errorMessage"
			v-for="question in visibleQuestions"
			:key="question.id"
			:data="data[question.id]"
			:id="question.id"
			@update="update"
		>
		</question>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Question from "@c/survey/render/Question";
// Loops through a list of available question IDs and a) filters for which should be visible based on rules and b) renders the question
// @group Survey
export default {
	name: "Questions",
	props: {
		// an array of survey IDs
		questions: { type: Array },
		// The current data for the survey section
		data: { type: Object },
		// If the question set has a current error e.g. disabled due to delegation
		error: { type: Boolean, default: false },
		// The relevant error message
		errorMessage: { type: String },
	},
	components: {
		Question,
	},
	computed: {
		visibleQuestions() {
			const self = this;
			return self.questions.filter((a) => {
				return self.mwsurveyutilities.visibilityTest(a, self.data);
			});
		},
	},
	methods: {
		// @vuese
		// @arg id - the question id 
		// @arg value - the value to set the question response to
		update(id, value) {
			// fired when an answer is provided
			this.$emit("update", id, value);
		},
	},
	watch: {
		visibleQuestions: {
			deep: true,
			handler(nv, ov) {
				var self = this;
				var visibleIds = nv.map((q) => q.id);
				if (ov) {
					ov.forEach((q) => {
						if (!visibleIds.find((v) => v == q.id)) {
							self.$emit("update", q.id, "");
						}
					});
				}
			},
		},
	},
};
//
</script>
"
