<template>
	<span>
		<poll-response-avatar v-if="response" :id="response">
		</poll-response-avatar>
	</span>
</template>
<script>
import PollResponseAvatar from "@c/survey/poll/PollResponseAvatar";
// Displays a poll response avatar where respondents to a poll have selected this answer option
// @group Polls

export default {
	name: "AnswerOptionPollResults",
	props: {
		// The poll document as found in the collaborations collection
		poll: { type: Object, required: true },
		// the question id
		question: { type: String, required: true },
		// the answer option value currently being tested
		answer: { type: String },
	},
	computed: {
		response() {
			var self = this;
			if (!self.poll.response) {
				return;
			}
			var response = self.poll.response[self.question];
			if (response == self.answer) {
				return self.poll.id;
			} else {
				return false;
			}
		},
	},
	data: () => {
		return {
			a: "123",
		};
	},
	components: {
		PollResponseAvatar,
	},
};
</script>
