<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<v-avatar size="30" v-on="on" :color="color" class="pa-3 mr-1">
				{{ initial }}
			</v-avatar>
		</template>
		<span v-if="poll.nickname.length">{{ poll.nickname }}'s </span>Antwort auf
		Ihre Mini-Umfrage
	</v-tooltip>
</template>

<style lang="less"></style>

<script type="text/javascript">
// Displays an avatar with the initiials of a nickname for a poll
// @group Polls
export default {
	name: "PollResponseAvatar",
	props: {
		// the id of the collaborations document
		id: { type: String },
	},
	computed: {
		polls() {
			return this.$store.getters["collaborations/currentViewPolls"];
		},
		poll() {
			var id = this.id;
			return this.polls.find((a) => a.id == id);
		},
		initial() {
			return this.poll.nickname.charAt(0) || " ";
		},
		color() {
			var index = this.polls.indexOf(this.poll);
			return this.$store.state.colors[index];
		},
	},
};
</script>
"
