<template>
	<v-row>
		<v-col>
			<div class="ig-question-wrapper">
				<div class="ig-question-title">
					{{ question.title }}
				</div>
				<div class="ig-question-body">
					<p class="ig-question-description">
						{{ question.description }}
					</p>
					<v-component
						:errorMessage="errorMessage"
						:is="componentName"
						:disabled="disabled"
						:id="id"
						:data="data"
						@update="update"
						@error="error = true"
					>
						<template v-slot:pollResults="slotProps" >
							<div class="text-center mt-2 poll-results" v-if="havePolls">
								<answer-option-poll-results
									v-for="poll in polls"
									:key="poll.id"
									:question="id"
									:answer="slotProps.answer"
									:poll="poll"
								></answer-option-poll-results>
							</div>
						</template>
					</v-component>
				</div>

				<error-message
					v-model="error"
					:message="errorMessage"
				></error-message>
			</div>
		</v-col>
	</v-row>
</template>

<style lang="less">

</style>

<script type="text/javascript">
import Vuex from "vuex";
import AnswerOptionPollResults from "@c/survey/poll/AnswerOptionPollResults";
import ErrorMessage from "@c/notices/ErrorMessage";
// The default component for rendering ANY question type - loads the correct component based on the questoin type
// @group Survey
export default {
	name: "Question",
	props: {
		// the ID of the question document
		id: { type: String },
		// The current value of the question response
		data: { type: [ Object, Array, String, Number ] },
		// Whether the question should be disabled (i.e. answer can not be changed )
		disabled: { type: Boolean },
		// The message to display when an error is emitted
		errorMessage: { type: String },
	},
	data: () => {
		return {
			error: false,
		};
	},
	components: {
		ErrorMessage,
		AnswerOptionPollResults,
		RadioTiles: () => import("@c/survey/questionTypes/RadioTiles"),
		Comment: () => import("@c/survey/questionTypes/Comment"),
	},
	computed: {
		...Vuex.mapState({
			question(state) {
				var id = this.id;
				return state.questions.data[id];
			},
		}),
		componentName() {
			if (this.question.type == "mwRadioTiles") {
				return "RadioTiles";
			}
			if (this.question.type == "mwComment") {
				return "Comment";
			}
			return "RadioTiles";
		},
		polls() {
			return this.$store.getters["collaborations/currentViewPolls"];
		},
		havePolls() {
			var self = this;
			var polls = self.polls.filter((a) => a.status == "submitted");
			polls = polls.filter((a) => a.response[self.id]);
			return polls.length > 0;
		},
	},
	methods: {
		// @vuese
		// @arg data - the value to set the question response to 
		update(data) {
			// fired when an answer is provided 
			if( typeof data == "undefined" ){
				data = "";
			}
			this.$emit("update", this.id, data);
		},
	},
	watch: {
		errorMessage() {
			this.error = false	
		}
	}
};
//
</script>
"
